import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getCompanyServices,
  getPackages,
  getServices,
} from 'pages/tours/actions';
import { actions } from 'pages/tours/store';
import { ADD_ONE_TO_INDEX, sqFt } from 'pages/tours/constants';
import {
  servicesSelector,
  companyServicesSelector,
  selectedSqFtSelector,
  selectedServicesSelector,
  selectedCompanyServicesSelector,
  currentStepSelector,
  selectedPackageServiceIdsSelector,
  stepsDataSelector,
  salesOptionsSelector,
  selectedPackageSalOptionSelector,
} from 'pages/tours/selectors';
import {
  checkLabelsDropdownState,
  giveAffordableSaleOption,
} from 'pages/tours/create/helpers';
import { showTotalPrice } from 'helpers/showTotalPrice';

import { OrderSummary } from 'pages/tours/create/new-steps/components/order-summary';
import { PackageItem } from 'pages/tours/create/new-steps/components/packageItem';
import { ServiceLabel } from 'components';
import { InformativeForward } from 'components/informativeForward';
import { STEPS } from 'pages/tours/create/constants';

// Images
import HomeIcon from 'assets/images/home.svg';

export const StepTwo = (props) => {
  const {
    filteredServices,
    filteredCompanyServices,
    getSubtotal,
    selectedPackage,
    getHST,
    getTotal,
    packages,
    isExistingFlow,
    getOtherTotal,
    getOtherHts,
    getCompanyServicesTotal,
  } = props;
  const [packageServices, setPackageServices] = useState([]);
  const [optionServices, setOptionServices] = useState([]);
  const [isOpenServiceOptionsId, setIsOpenServiceOptionsId] = useState(null);

  const services = useSelector(servicesSelector);
  const selectedPackageSalOption = useSelector(
    selectedPackageSalOptionSelector
  );
  const salesOptions = useSelector(salesOptionsSelector);
  const companyServices = useSelector(companyServicesSelector);
  const selectedServices = useSelector(selectedServicesSelector);
  const selectedCompanyServices = useSelector(selectedCompanyServicesSelector);
  const selectedSqFt = useSelector(selectedSqFtSelector);
  const currentStep = useSelector(currentStepSelector);
  const selectedPackageServiceIds = useSelector(
    selectedPackageServiceIdsSelector
  );
  const stepsData = useSelector(stepsDataSelector);
  const chosenOptionsObj = useSelector((state) => state.tours.chosenOptionsObj);

  const dispatch = useDispatch();

  const showTotal = showTotalPrice(getOtherTotal);

  const changeSqFt = (sq) => {
    if (Number(sq) === Number(selectedSqFt)) {
      dispatch(actions.setSelectedServices([]));
      dispatch(actions.setSelectedPackage({}));
      dispatch(actions.setSelectedPackageServiceIds([]));
      dispatch(actions.setSelectedPackageSalOption([]));
      dispatch(actions.setSalesOptions([]));
      setPackageServices([]);
      setOptionServices([]);
      dispatch(getPackages(0));
      dispatch(getServices({ price: 0 }));
      dispatch(actions.setSelectedSqFt(0));
    } else {
      dispatch(getPackages(sq));
      dispatch(getServices({ price: sq }));
      dispatch(actions.setSelectedSqFt(sq));
    }
  };

  const selectService = (service) => {
    const id = service?.id;

    if (!selectedSqFt) {
      return;
    }
    let serviceIds = [];
    if (
      selectedServices.includes(id) &&
      !selectedPackageServiceIds.includes(id)
    ) {
      serviceIds = new Set([...selectedServices]);

      service?.active_services_ids.forEach(serviceIds.delete, serviceIds);

      serviceIds.delete(id);
    } else {
      serviceIds = new Set([...selectedServices, id]);

      service?.inactive_services_ids.forEach(serviceIds.delete, serviceIds);
    }

    dispatch(
      actions.setServices({
        data: services,
        selected_ids: [...serviceIds, ...packageServices, ...optionServices],
      })
    );

    giveAffordableSaleOption({
      serviceIds: [...serviceIds],
      dispatch,
      actions,
      selectedPackageSalOption,
      salesOptions,
    });
    dispatch(actions.setSelectedServices([...serviceIds]));
  };

  const selectCompanyService = (service) => {
    const id = service?.id;

    let serviceIds = [];
    if (selectedCompanyServices.includes(id)) {
      serviceIds = selectedCompanyServices.filter((el) => el !== id);
    } else {
      serviceIds = new Set([...selectedCompanyServices, id]);
      const selService = companyServices.find((serv) => serv.id === id);
      dispatch(
        actions.setChosenOptionsObj({
          ...chosenOptionsObj,
          [id]: chosenOptionsObj?.[id] || selService?.pricing_options?.[0]?.id,
        })
      );
    }

    dispatch(actions.setSelectedCompanyServices([...serviceIds]));
  };

  const selectPackage = (pack) => {
    if (!selectedSqFt) {
      return;
    }
    const ids = [];
    const packageDisableServiceIDs = [];

    const packageServicesIds = pack?.dashboard_services?.map((elem) => elem.id);

    services.forEach((elem) => {
      if (packageServicesIds.includes(elem?.id)) {
        packageDisableServiceIDs.push(...elem?.inactive_services_ids);
      } else {
        packageDisableServiceIDs.push(...elem?.active_services_ids);
      }
    });

    let selServices = [];

    if (selectedPackage.id === pack.id) {
      dispatch(actions.setSelectedPackage({}));
      dispatch(actions.setSelectedPackageServiceIds([]));
      dispatch(actions.setSelectedPackageSalOption([]));
      dispatch(actions.setSalesOptions([]));

      const removedDisabledServices = selectedServices?.filter(
        (servId) => !packageDisableServiceIDs.includes(servId)
      );

      selServices = [...removedDisabledServices];

      dispatch(actions.setSelectedServices(removedDisabledServices));
    } else {
      dispatch(actions.setSelectedPackage(pack));
      dispatch(actions.setSalesOptions([]));
      pack?.dashboard_services?.forEach((element) => {
        ids.push(element.id);
      });
      dispatch(actions.setSelectedPackageServiceIds(ids));
      dispatch(
        actions.setSelectedPackageSalOption(pack?.dashboard_sale_options)
      );

      const packageServices = services?.filter((elem) =>
        ids.includes(elem?.id)
      );

      const nonPackageServices = selectedServices?.filter(
        (servId) =>
          !ids.includes(servId) && !packageDisableServiceIDs.includes(servId)
      );

      selServices = [...nonPackageServices];

      dispatch(actions.setSelectedServices(nonPackageServices));

      giveAffordableSaleOption({
        serviceIds: [...nonPackageServices],
        dispatch,
        actions,
        selectedPackageSalOption: pack?.dashboard_sale_options,
        salesOptions,
      });
    }
    const serviceIds = new Set(ids);
    setPackageServices([...serviceIds]);
    dispatch(
      actions.setServices({
        data: services,
        selected_ids: [...serviceIds, ...optionServices, ...selServices],
      })
    );
  };

  const handleOpenServicesOptionsDropdown = (e, id) => {
    e.stopPropagation();
    if (isOpenServiceOptionsId === id) {
      setIsOpenServiceOptionsId(null);
    } else {
      setIsOpenServiceOptionsId(id);
    }
  };

  const onSubmit = () => {
    if (!selectedPackage.id && !selectedServices?.length) {
      return;
    }
    dispatch(actions.setCurrentStep(currentStep + 1));
    const formattedCompanyServices = [];

    selectedCompanyServices.forEach((id) => {
      if (chosenOptionsObj[id]) {
        formattedCompanyServices.push({
          id: Number(id),
          option_id: Number(chosenOptionsObj[id]),
        });
      } else {
        formattedCompanyServices.push({ id: Number(id), option_id: null });
      }
    });

    const data = {
      sqft: selectedSqFt,
      service: selectedServices,
      package: selectedPackage?.id,
      company_services: formattedCompanyServices,
      total_cost: getTotal,
      discount: 13,
      subtotal: getSubtotal,
    };
    dispatch(actions.setStepsData({ ...stepsData, ...data }));
  };

  const handleOptionSelect = ({ serviceId, optionId }) => {
    dispatch(
      actions.setChosenOptionsObj({
        ...chosenOptionsObj,
        [serviceId]: Number(optionId),
      })
    );
  };

  const handleClickOurWorkPageForward = () => {
    const formattedCompanyServices = [];

    selectedCompanyServices.forEach((id) => {
      if (chosenOptionsObj[id]) {
        formattedCompanyServices.push({
          id: Number(id),
          option_id: Number(chosenOptionsObj[id]),
        });
      } else {
        formattedCompanyServices.push({ id: Number(id), option_id: null });
      }
    });

    const data = {
      sqft: selectedSqFt,
      service: selectedServices,
      package: selectedPackage?.id,
      company_services: formattedCompanyServices,
      total_cost: getTotal,
      discount: 13,
      subtotal: getSubtotal,
    };
    dispatch(actions.setStepsData({ ...stepsData, ...data }));
  };

  useEffect(() => {
    dispatch(getServices({ price: selectedSqFt || 0 }));
    dispatch(getCompanyServices());
  }, []);

  useEffect(() => {
    if (
      selectedPackage &&
      Object.keys(selectedPackage).length &&
      packages?.length
    ) {
      const currentPackage = packages?.find(
        (pack) => pack.id === selectedPackage.id
      );
      dispatch(actions.setSelectedPackage(currentPackage));
    }
  }, [selectedSqFt, dispatch, packages, selectedPackage]);

  useEffect(() => {
    const serviceIds = [];
    salesOptions?.forEach((option) => {
      const services = option.dashboard_pricings?.map((service) => service.id);
      serviceIds.push(...services);
    });

    const nonOptionServices = selectedServices.filter(
      (servId) => !serviceIds.includes(servId)
    );

    dispatch(actions.setSelectedServices(nonOptionServices));
    setOptionServices(serviceIds);
  }, [salesOptions]);

  return (
    <div className="dashboardMain">
      <div className="card">
        <h3 className="heading3">{STEPS.packages_services}</h3>
        <InformativeForward
          infoText={STEPS.info_text}
          buttonText={STEPS.button_text}
          forwardPath={'/our-work'}
          onClick={handleClickOurWorkPageForward}
        />
        {isExistingFlow ? (
          <>
            <div className="captionForm">
              <h4>{STEPS.property_sq_ft}</h4>
            </div>
            <ul className="selectSizeList existing_size_list">
              <li className={'active'}>
                <div>
                  <i>
                    <img src={HomeIcon} alt="home" />
                  </i>
                  <span>
                    &lt; {selectedSqFt}
                    <small>{STEPS.sq_ft}</small>
                  </span>
                </div>
              </li>
            </ul>
          </>
        ) : (
          <>
            <div className="captionForm">
              <h4>{STEPS.select_sq_ft}</h4>
              <p>
                <i>{STEPS.sq_ft_note}</i>
              </p>
            </div>
            <ul className="selectSizeList">
              {sqFt?.map((sq, index) => (
                <li
                  className={`${!selectedSqFt && 'sq_ft_animation_active'} ${
                    selectedSqFt === sq.value ? 'active' : ''
                  }`}
                  key={index}
                  onClick={() => {
                    changeSqFt(sq.value);
                  }}
                >
                  <div>
                    <i>
                      <img src={HomeIcon} alt="home" />
                    </i>
                    <span>
                      {sq.title === STEPS.sq_ft_title && <span> &lt; </span>}
                      {sq.title}
                      <small>{STEPS.sq_ft}</small>
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
        <div className="row packages_services_container">
          <div>
            <div className="form-group">
              <label className="package_label" htmlFor="">
                {STEPS.select_package}
              </label>
              <div className="packages_container">
                {packages.map((pack, index) => (
                  <PackageItem
                    key={pack.id}
                    pack={pack}
                    selectPackage={selectPackage}
                    selectedSqFt={selectedSqFt}
                    isSelected={selectedPackage?.id === pack?.id}
                    selectedPackage={selectedPackage}
                    isLastItem={index + ADD_ONE_TO_INDEX === packages.length}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="col-xxl-9 col-lg-8">
            <div className="form-group">
              <label htmlFor="">{STEPS.add_ons}</label>
              <ul className="labelList">
                {services?.map((service) => {
                  return (
                    <ServiceLabel
                      key={service.id}
                      service={service}
                      selectedServices={selectedServices}
                      selectService={selectService}
                      isOpenServiceOptionsId={isOpenServiceOptionsId}
                      handleOpenServicesOptionsDropdown={
                        handleOpenServicesOptionsDropdown
                      }
                      checkLabelsDropdownState={checkLabelsDropdownState}
                      handleOptionSelect={handleOptionSelect}
                      hidden={
                        packageServices.includes(service.id) ||
                        selectedPackageServiceIds.includes(service.id)
                      }
                      disabled={
                        packageServices.includes(service.id) ||
                        selectedPackageServiceIds.includes(service.id) ||
                        optionServices.includes(service.id)
                      }
                      className={`ctaService ${
                        selectedServices?.includes(service.id) ? 'active' : ''
                      }`}
                      withOptions={false}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="col-xk-6">
            <div className="form-group more_services_container">
              <label htmlFor="">{STEPS.more_services}</label>
              <ul className="labelList">
                {companyServices?.map((service) => {
                  return (
                    <ServiceLabel
                      key={service.id}
                      service={service}
                      selectedServices={selectedCompanyServices}
                      selectService={selectCompanyService}
                      isOpenServiceOptionsId={isOpenServiceOptionsId}
                      handleOpenServicesOptionsDropdown={
                        handleOpenServicesOptionsDropdown
                      }
                      checkLabelsDropdownState={checkLabelsDropdownState}
                      handleOptionSelect={handleOptionSelect}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
        </div>

        <div className="packageStep">
          <div className="row">
            <div className="col-lg-6">
              <div className="cardCharge cstWidth">
                <h3 className="later_charge_title">{STEPS.charge_later}</h3>
                <p className="note">{STEPS.charge_text}</p>
                <div className="titleCharge">
                  <h4>{STEPS.more_services}</h4>
                  <h4>{STEPS.price}</h4>
                </div>
                <ul>
                  {filteredCompanyServices.map((service, i) => (
                    <li key={i}>
                      <span>
                        {service.title.charAt(0).toUpperCase() +
                          service.title.slice(1)}
                      </span>{' '}
                      {service.price || service?.price_fixed
                        ? `$${service.price || service?.price_fixed}`
                        : STEPS.not_specified}
                    </li>
                  ))}
                </ul>
                <div className="totalCard">
                  <div className="amount">
                    <p className="subTotal">
                      <span>{STEPS.subtotal}</span>
                      <strong>${getCompanyServicesTotal}</strong>
                    </p>
                    <p>
                      <span>{STEPS.hst}</span>
                      <strong>
                        {STEPS.hst_percent} (${getOtherHts})
                      </strong>
                    </p>
                  </div>
                  <div className="total later_charge_total">
                    <p>
                      <span>{STEPS.total}</span> <strong>{showTotal}</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 row justify-content-start justify-content-lg-end  m-0">
              <OrderSummary
                title={STEPS.payable_now}
                packages={packages}
                selectedPackage={selectedPackage}
                filteredServices={filteredServices}
                filteredCompanyServices={filteredCompanyServices}
                getSubtotal={getSubtotal}
                getHST={getHST}
                getTotal={getTotal}
                packageServices={packageServices}
                selectedSqFt={selectedSqFt}
              />
            </div>
          </div>
        </div>

        <div className="text-end footerCta">
          <button
            id="prev_button"
            className="btn cta-outline-primary"
            onClick={() => dispatch(actions.setCurrentStep(1))}
          >
            {STEPS.previous}
          </button>
          <button
            className={`btn cta-primary ${
              !selectedPackage?.id && !selectedServices?.length && 'disabled'
            }`}
            id="next_button"
            onClick={onSubmit}
            disabled={!selectedPackage?.id && !selectedServices?.length}
          >
            {STEPS.next}
          </button>
        </div>
      </div>
    </div>
  );
};
